<template>
    <div class="m-classify-nav">
        <h1>{{curSite.name}}</h1>
        <h2>{{curSite.nameEn}}</h2>
        <ul class="m-detail-nav">
            <li v-for="tab in tabList" :class="{'active':tab.typeId==typeId}" @click="tabBtn(tab)"
                :key="tab.typeId">
                {{tab.typeName}}
            </li>
        </ul>
        <div class="m-line"></div>
    </div>
</template>

<script>
    export default {
        name: "detailNav",
        props: ["tabList", 'curSite'],
        data() {
            return {
                typeId: '',
            }
        },
        mounted() {
            this.typeId = this.$route.query.typeId
        },
        watch: {
            $route() {
                this.typeId = this.$route.query.typeId
            }
        },
        methods: {
            tabBtn(tab) {
                if (tab.typeId == this.typeId) {
                    return
                }
                this.$emit('changeTab', tab)
            }
        }
    }
</script>

<style scoped>
    .m-classify-nav {
        width: 100%;
        background-size: 100%;
        padding: 1rem 1rem .6rem;
    }

    .m-line {
        display: block;
        margin: 0 1rem;
        border-bottom: 1px solid #d9d9d9;
    }

    .m-classify-nav h1 {
        font-size: 20px;
        color: #000;
        text-align: center;
        font-weight: normal;
        margin-top: 1.66666666667rem;
        margin-bottom: .4rem;
    }

    .m-classify-nav h2 {
        font-size: .8rem;
        color: #b3b6b5;
        text-align: center;
        font-weight: normal;
    }

    .m-detail-nav {
        display: flex;
        display: -webkit-flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 2rem;
    }

    .m-detail-nav li {
        width: 33%;
        font-size: 17px;
        color: #000;
        text-align: center;
        padding: .4rem 0;
        margin-bottom: 1rem;
        box-sizing: border-box;
    }

    .m-classify-nav li.active {
        color: #005bac;
		position: relative;
    }

    .m-classify-nav li.active::after {
        content: "";
        position: absolute;
        left: 50%;
        right: 0;
        width: 76%;
        transform: translateX(-50%);
        bottom: 0;
        height: 2px;
        background: #005bac;
    }

</style>
